<template>
  <div>
    <div class="card">
      <div class="nav-panel anan-tabs anan-tabs-line anan-tabs-normal anan-tabs-top">
        <div class="anan-tabs__nav">
          <div
            class="anan-tabs__nav-warp px-2"
            style="margin-top: 5px;"
          >
            <div
              class="anan-tabs__nav-tabs"
              style="transform: translateX(0px)"
            >
              <div
                class="anan-tabs__nav-tab"
                :class="{ active: isActive === 'all' }"
                style="white-space: normal"
                @click="GetOrderAll(status = 0), isActive = 'all'"
              >
                <span> {{ $t('all') }} </span>
              </div>

              <div
                class="anan-tabs__nav-tab"
                :class="{ active: isActive === 'waiting paid' }"
                style="white-space: normal"
                @click="GetOrderAll(status = 1), isActive = 'waiting paid'"
              >
                <span> {{ $t('awaitingPayment') }} </span>
              </div>

              <div
                class="anan-tabs__nav-tab"
                :class="{ active: isActive === 'waiting' }"
                style="white-space: normal"
                @click="GetOrderAll(status = 2), isActive = 'waiting'"
              >
                <span> {{ $t('pendingReview') }} </span>
              </div>

              <div
                class="anan-tabs__nav-tab"
                :class="{ active: isActive === 'success' }"
                style="white-space: normal"
                @click="GetOrderAll(status = 3), isActive = 'success'"
              >
                <span> {{ $t('completed') }} </span>
              </div>

              <div
                class="anan-tabs__nav-tab"
                :class="{ active: isActive === 'cancel' }"
                style="white-space: normal"
                @click="GetOrderAll(status = 4), isActive = 'cancel'"
              >
                <span> {{ $t('cancel') }} </span>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="d-lg-flex justify-content-between align-items-center px-2">
        <h3 class="font-weight-bolder">
          {{ $t('payShippingFee') }}
        </h3>

        <div class="d-flex align-items-center">
          <div class="anan-input__inner anan-input__inner--normal">
            <input
              v-model="search"
              type="text"
              :placeholder="$t('search')"
              class="anan-input__input"
              @keyup.enter="GetOrderAll(status)"
            >
            <div class="anan-input__suffix">
              <button
                type="button"
                class="anan-button anan-button--normal anan-button--primary"
                @click="GetOrderAll(status)"
              >
                <i class="fal fa-search" />
              </button>
            </div>

          </div>
        </div>
      </div>
      <b-overlay
        :show="showOver"
        variant="transparent"
        opacity="0.99"
        blur="5px"
        rounded="sm"
      >
        <template #overlay>
          <div class="text-center">
            <p id="cancel-label">
              กรุณารอสักครู่...
            </p>
          </div>
        </template>
        <div class="p-2">
          <b-table
            striped
            hover
            responsive
            :per-page="perPage"
            :items="items"
            :fields="fields"
            show-empty
          >
            <!--   :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn" -->
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner
                  label="กำลังโหลด..."
                  variant="danger"
                />
              </div>
            </template>

            <template #empty>
              <div class="text-center">
                <img
                  src="@/assets/images/anan-img/empty/empty.png"
                  alt="empty"
                  width="180px"
                >
                <p>No Data</p>
              </div>
            </template>
            <template #cell(number)="data">
              <b-link
                :to="{name: 'shipment_payment_edit', params: {id: data.item._id}}"
                target="_blank"
              >
                <!-- <span class="mb-0 text-black badge badge-warning"`````> {{ getType(data.item.transportType) }} </span> -->
                <span>{{ data.item.itemnumber_text }}</span>
              </b-link>
            </template>
            <template #cell(payment)="data">
              {{ Commas(data.item.payment) }}
            </template>
            <template #cell(customer)="data">
              <b-link
                :to="{name: 'admin-employee-edit', params: {id: data.item.uid}}"
                target="_blank"
              >
                <!-- <span class="mb-0 text-black badge badge-warning"`````> {{ getType(data.item.transportType) }} </span> -->
                <span>{{ data.item.username }}</span>
              </b-link>
            </template>
            <template #cell(transactionDate)="data">
              {{ time(data.item.created_at) }}
            </template>
            <template #cell(approvalDate)="data">
              {{ data.item.payNow === 3 ? '-': data.item.dateokay ? data.item.dateokay:  '-'}}
            </template>
            <template #cell(noun)="data">
              <div v-if="data.item.payNow === 2 ">
                <div v-if="data.item.receipt">
                  <router-link :to="{name : 'admin-finance-receipt_edit', params: { id: data.item._id }}">
                    {{ data.item.billnumber }}
                  </router-link>
                  <br>
                  {{ data.item.texfullname }}
                </div>
                <div
                  v-else
                  class="w-50 badge badge-warning col-md-4"
                  @click="createbill(data.item)"
                > {{
                  $t('IssueReceipt') }}
                </div>
              </div>
              <div v-if="data.item.payNow === 3">
                <div v-if="data.item.receipt">
                  <router-link :to="{name : 'admin-finance-receipt_edit', params: { id: data.item._id }}">
                    {{ data.item.billnumber }}
                  </router-link>
                  <br>
                  {{ data.item.texfullname }}
                </div>

              </div>
            </template>
            <template #cell(amount)="data">
              {{ data.item.postnumber_id.length }}
            </template>
            <!-- <template #cell(number)="data">
            <b-link :to="{name: 'admin-user-type-edit', params: {id: data.item.id}}">
              GUSR-{{ items.length - (perPage * (currentPage - 1) + (data.index + 1)) + 1 }}
            </b-link>
          </template>

          <template #cell(index)="data">
            {{ perPage * (currentPage - 1) + (data.index + 1) }}
          </template>

          <template #cell(transactionDate)="data">
            {{ time(data.item.transactionDate) }}
          </template>

          <template #cell(amount)="data">
            {{ Commas(data.item.amount) }}฿
          </template>

          <template #cell(document)="data">
            <img
              :src="data.item.document"
              alt="img-document"
              class="border"
              height="40"
            >
          </template> -->

            <template #cell(status)="data">
              <span
                v-if="data.item.payNow === 0"
                class="badge badge-warning badge-pill"
              > {{ $t('awaitingPayment') }}
              </span>
              <span
                v-if="data.item.payNow === 1"
                class="badge badge-primary badge-pill"
              > {{ $t('pendingReview') }}
              </span>
              <span v-if="data.item.payNow === 2">
                <span
                  v-if="data.item.order_out === 1"
                  class="badge badge-danger badge-pill"
                > {{ $t('awaitingPickup') }}
                </span>
                <span
                  v-else-if="data.item.order_out === 2"
                  class="badge badge-success badge-pill"
                > {{ $t('receivedItem')
                }} </span>
                <span
                  v-else-if="data.item.order_thaiout === 1"
                  class="badge badge-info badge-pill"
                > {{
                  $t('awaitingDeliveryTH') }} </span>
                <span
                  v-else-if="data.item.order_thaiout === 2"
                  class="badge badge-success badge-pill"
                > {{ $t('delivered')
                }} </span>
              </span>
              <span
                v-if="data.item.payNow === 3"
                class="badge badge-secondary badge-pill"
              > {{ $t('cancelled') }} </span>

            </template>
              <template #cell(deliverytype)="data">
              <span>{{ data.item.typedelivery.pro_name }}</span><br>
              <span :class="`${data.item.typedelivery.paydelivery === 'จ่ายค่าขนส่งต้นทาง' ? 'text-danger': 'text-warning'}`">{{ data.item.typedelivery.paydelivery }}</span>
            </template>
            <template #cell(actions)="data">
              <router-link
                :to="{name : 'shipment_payment_edit', params: { id: data.item._id }}"
                target="_blank"
              >
                <feather-icon
                  icon="Edit3Icon"
                  class="mr-1 text-primary cursor-pointer"
                />
              </router-link>
            </template>

          <!-- <router-link :to="{name : 'shipment_payment_edit'}">
              <feather-icon
                icon="Edit3Icon"
                class="mr-1 text-primary cursor-pointer"
              />
            </router-link> -->
          </b-table>

          <div class="demo-spacing-0 d-flex justify-content-end">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
              @input="GetOrderAll(status)"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </b-overlay>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment-timezone'
import {
  BPagination, BTable, BSpinner, BOverlay, BLink,
} from 'bootstrap-vue'

export default {
  components: {
    BPagination,
    BLink,
    BTable,
    BSpinner,
    BOverlay,
  },
  data() {
    return {
      search: null,
      isActive: 'all',
      selectedRadio: 'active',
      optionsRadio: [
        { text: 'All', value: 'all' },
        { text: 'Car', value: 'car' },
        { text: 'Ship', value: 'ship' },
      ],
      pageOptions: [5, 10, 15, 20],
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      items: [],
      pic: null,

      total: 0,
      notpaid: 0,
      waitingforcheck: 0,
      waitingforpayment: 0,
      paid: 0,
      OrderSuccess: 0,
      completetrack: 0,
      completewarehouse: 0,
      cancel: 0,
      status: 0,
      showOver: false,
    }
  },
  computed: {
    fields() {
      return [
        {
          key: 'number', label: this.$t('number'), thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'customer', label: this.$t('customer'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'transactionDate', label: this.$t('transactionDate'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'approvalDate', label: this.$t('approvalDate'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
          {
          key: 'deliverytype', label: this.$t('deliveryType'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center',
        },
        {
          key: 'noun', label: this.$t('receipt'), sortable: false, thStyle: { width: '25%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'payment', label: this.$t('quantity'), sortable: false, thStyle: { width: '20%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'status', label: this.$t('status'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'actions', label: this.$t('actions'), sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
      ]
    },
  },
  watch: {
    '$route.query.querykey': {
      immediate: true,
      handler() {
        this.handleQueryChange()
      },
    },
  },
  mounted() {
    this.GetNotify()
    this.handleQueryChange()
    // this.GetOrderAll(this.status)
  },
  methods: {
    handleQueryChange() {
      const { querykey } = this.$route.query
      if (querykey) {
        if (Number(querykey) === 1) {
          this.isActive = 'waiting paid'
          this.GetOrderAll(this.status = 1)
        }
        if (Number(querykey) === 2) {
          this.isActive = 'waiting'
          this.GetOrderAll(this.status = 2)
        }
      } else {
        this.GetOrderAll(this.status)
      }
    },
    // Commas(num) {
    //   return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    // },
    time(data) {
      return moment(data).format('YYYY-MM-DD HH:mm:ss')
    },
    GetNotify() {
      this.$http
        .get('/order/Notify')
        .then(response => {
          // console.log(response.data)
          this.total = response.data.total
          this.waitingforcheck = response.data.waitingforcheck
          this.waitingforpayment = response.data.waitingforpayment
          this.paid = response.data.paid
          this.OrderSuccess = response.data.OrderSuccess
          this.completetrack = response.data.completetrack
          this.completewarehouse = response.data.completewarehouse
          this.cancel = response.data.cancel
        })
        .catch(error => console.log(error))
    },
    async GetOrderAll(type) {
      this.showOver = true
      try {
        const params = {
          page: this.currentPage,
          perPage: this.perPage,
          search: this.search,
          type,
        }
        const { data: res } = await this.$http.get('/StatusPayment/getdata', { params })
        this.items = res.data
        // this.totalRows = this.items.length
        this.totalRows = res.total
        this.showOver = false
      } catch (err) {
        console.log(err)
        this.showOver = false
      }
    },
    createbill(data) {
      this.$bvModal.msgBoxConfirm('ต้องการออกใบเสร็จ?', {
        title: 'ยืนยัน',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'warning',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
        .then(value => {
          if (value) {
            const obj = {
              // eslint-disable-next-line no-underscore-dangle
              id: data._id,
            }
            this.$http.post('/Billpay/store', obj)
              .then(() => {
                // eslint-disable-next-line no-underscore-dangle
                this.$router.push({ name: 'admin-finance-receipt_edit', params: { id: data._id } })
              })
              .catch(err => {
                this.SwalError(err)
              })
          }
        })
    },
    resize(event) {
      // eslint-disable-next-line no-param-reassign
      event.target.style.height = 'auto'
      // eslint-disable-next-line no-param-reassign
      event.target.style.height = `${event.target.scrollHeight}px`
    },
    updateKeyword(id, note) {
      // console.log('Item ID:', id)
      // console.log('note:', note)
      const params = {
        id,
        note,
      }
      // console.log(params)
      this.$http
        .post('/order/updateNote', params)
      // eslint-disable-next-line no-unused-vars
        .then(response => {
          // console.log(response.data)
        })
        .catch(error => console.log(error))
    },
    showModal(data) {
      this.pic = data
      this.$refs['my-modal'].show()
    },
    downloadImg() {
      const imageURL = '@/assets/images/anan-img/example/1.jpg'
      const link = document.createElement('a')
      link.href = imageURL
      link.download = 'image.jpg'
      link.click()
    },
  },
}
</script>

      <style lang="scss" scoped></style>
